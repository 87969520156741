<template>
  <CTabs class="zq--tabs">
    <CTab title="Connections"><ConnectionsTable /></CTab>
<!--    <CTab title="SQS"><SqsConnectionsTable /></CTab>-->
<!--    <CTab title="Kafka"><KafkaConnectionsTable/></CTab>-->
<!--    <CTab title="RabbitMQ"><RabbitMqConnectionsTable /></CTab>-->
  </CTabs>
</template>
<script>
// import SqsConnectionsTable from '@/views/ziqni/settings/connections/SqsConnectionsTable';
// import KafkaConnectionsTable from '@/views/ziqni/settings/connections/KafkaConnectionsTable';
// import RabbitMqConnectionsTable from '@/views/ziqni/settings/connections/RabbitMqConnectionsTable';
import ConnectionsTable from '@/views/ziqni/settings/connections/ConnectionsTable';

export default {
  components: {
    ConnectionsTable,
    // SqsConnectionsTable,
    // KafkaConnectionsTable,
    // RabbitMqConnectionsTable,
  },
  data() {
    return {};
  },
};
</script>
