<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    model="sqsConnections"
  />
</template>
<script>
import { sqsConnections } from '@/config/descriptions/sqsConnections';
import { sqsConnectionsTexts } from '@/config/pageTexts/sqsConnections.json';

export default {
  name: 'SqsConnectionsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'sqsConnections/sqsConnections',
          fields: 'sqsConnections/fields',
          sortableFields: 'sqsConnections/sortableFields',
          pages: 'sqsConnections/pages',
          loading: 'sqsConnections/loading',
          resultCount: 'sqsConnections/resultCount',
          totalRecords: 'sqsConnections/totalRecords',
          originalFields: 'sqsConnections/originalFields',
          searchableAllFields: 'sqsConnections/searchableAllFields',
          success: 'sqsConnections/success',
          message: 'sqsConnections/message',
        },
        actions: {
          fields: 'sqsConnections/handleFields',
          delete: 'sqsConnections/handleDeleteConnections',
          reset: 'sqsConnections/handleClearFilter',
          query: 'sqsConnections/handleGetConnectionsByQuery',
          status: 'sqsConnections/handleUpdateConnectionsState',
        },
      },
      settings: {
        describe: { ...sqsConnections },
        page: { ...sqsConnectionsTexts },
      },
      route: {
        create: {sqs: 'CreateSqsConnection', kafka: 'CreateKafkaConnection', rabbitMq: 'CreateRabbitMqConnection', scheduled: 'CreateScheduledConnection'},
        edit: 'EditConnection',
        preview: 'PreviewConnection',
      },
    };
  },
};
</script>